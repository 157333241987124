import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Chip,
  Autocomplete,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import Toaster from '../../../../Toaster/Toaster';
import { url } from '../../../../Helpers/utils/Constant';
import { Helmet } from 'react-helmet-async';
import { muiStyles } from '../../../../Utils/Styles';
import { useAuthContext } from 'src/components/Context/AuthContext/AuthContext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { ISpeaker } from 'src/Utils/type';
import moment from 'moment';

const useStyles = muiStyles;
interface IProps {
  handleClose: Function;
  setAllEvents: Function;
}

const CreateEvent = ({ handleClose, setAllEvents }: IProps) => {
  const [startValue, setStartValue] = React.useState<any>();
  const { user } = useAuthContext();
  const classes = useStyles();
  const Toast = Toaster();
  const { register, handleSubmit, reset } = useForm();
  const [allSpeakers, setAllSpeakers] = useState<ISpeaker[]>([]);
  const [speakerId, setSpeakerId] = useState<string[]>([]);
  const currentDate = new Date();
  const [timeValue, setTimeValue] = useState({
    event_start_time: dayjs(currentDate),
    event_end_time: dayjs(currentDate),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedEventType, setSelectedEventType] = useState<any | null>(1); // Default to 1 or the appropriate default value

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(`${url}/api/fair/get/speaker/all/all`);
      if (data.success) {
        setAllSpeakers(data.data);
      }
    })();
  }, []);

  const selectedSpeaker = speakerId.map((speaker) => {
    return parseInt(speaker.split(' ')[0]);
  });

  const [endValue, setEndValue] = React.useState<any>();

  const onSubmit = async (inputs: any) => {
    setLoading(true);

    inputs.event_created_by = user.user_admin_id;
    // inputs.event_speaker = JSON.stringify(selectedSpeaker);
    const startTime = moment(startValue.toISOString());
    inputs.event_start_time = startTime.format('HH:mm:ss');

    const endTime = moment(endValue.toISOString());
    inputs.event_end_time = endTime.format('HH:mm:ss');
    if (selectedEventType === 1) {
      // Only include event_speaker if Tourism Development Summit is selected
      inputs.event_speaker = JSON.stringify(selectedSpeaker);
    }
    try {
      const bodyFormData = new FormData();

      for (const key in inputs) {
        if (typeof inputs[key] === 'object') {
          bodyFormData.append(key, inputs[key][0]);
        } else {
          bodyFormData.append(key, inputs[key]);
        }
      }
      const { data } = await axios.post(
        `${url}/api/fair/event/for-admin/create`,
        bodyFormData
      );
      if (data.success) {
        Toast.fire({
          icon: 'success',
          title: data.message,
        });
        setAllEvents((prev) => [
          {
            event_id: data.data.fair_event_id,
            event_title: inputs.event_title,
            event_venu: inputs.event_venu,
            event_date: inputs.event_date,
            event_photo: data.data.event_photo,
            event_start_time: inputs.event_start_time,
            event_end_time: inputs.event_end_time,
            event_status: 'upcoming',
          },
          ...prev,
        ]);
        setTimeValue({
          event_start_time: dayjs(currentDate),
          event_end_time: dayjs(currentDate),
        });
        reset();
        setLoading(false);
        handleClose();
      } else {
        Toast.fire({
          icon: 'error',
          title: data.message,
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <>
      <Helmet>
        <title>Fair - Create Event</title>
      </Helmet>

      <Box sx={{ p: 2 }} className=''>
        <Box
          component='form'
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            mt: 1,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <FormControl fullWidth margin='normal' required>
                <InputLabel id='select-event-label'>Select Event</InputLabel>
                <Select
                  labelId='select-event-label'
                  id='select-event'
                  {...register('fair_seminar_id')}
                  autoFocus
                  label='Select Event'
                  onChange={(e) => setSelectedEventType(e.target.value)}
                >
                  <MenuItem value={4}>Tourism Development Summit</MenuItem>
                  <MenuItem value={2}>Children Art Competition</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='event title'
                label='Enter event title'
                {...register('event_title')}
                type='text'
                autoFocus
                className={classes.inputText}
                InputProps={{ classes }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='event type'
                label='Enter event type'
                {...register('event_type')}
                type='text'
                autoFocus
                className={classes.inputText}
                InputProps={{ classes }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='event venue'
                label='Enter event venue '
                {...register('event_venu')}
                type='text'
                autoFocus
                className={classes.inputText}
                InputProps={{ classes }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='event date'
                label='Enter event date'
                InputLabelProps={{ shrink: true }}
                {...register('event_date')}
                type='date'
                autoFocus
                className={classes.inputText}
                InputProps={{ classes }}
              />
              {/* <TextField
                label='Enter event date'
                fullWidth
                type='date'
                onChange={handleDateChange}
                defaultValue={getCurrentDate()}
                InputLabelProps={{ shrink: true }}
                {...register('event_date')}
              /> */}
            </Grid>

            <Grid item xs={12} md={6} lg={4} mt={2}>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
              {/* <TimePicker
                label='Start time'
                value={timeValue.event_start_time}
                onChange={(value: Dayjs | null) =>
                  setTimeValue({
                    ...timeValue,
                    event_start_time: value,
                  })
                }
              /> */}
              {/* <TimePicker
                label='Start time'
                value={timeValue.event_start_time}
                onChange={(value: Dayjs | null) =>
                  setTimeValue({
                    ...timeValue,
                    event_start_time: value,
                  })
                }
                renderInput={(params) => (
                  <TextField {...params} fullWidth required />
                )}
              /> */}
              {/* </LocalizationProvider> */}

              <TimePicker
                label='Start Time'
                value={startValue}
                onChange={(newValue) => setStartValue(newValue)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4} mt={2}>
              {/* <TimePicker
                label='End time'
                value={timeValue.event_end_time}
                onChange={(value: Dayjs | null) =>
                  setTimeValue({
                    ...timeValue,
                    event_end_time: value,
                  })
                }
              /> */}
              <TimePicker
                label='End Time'
                value={endValue}
                onChange={(newValue) => setEndValue(newValue)}
              />

              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label='End time'
                  value={timeValue.event_end_time}
                  onChange={(value: Dayjs | null) =>
                    setTimeValue({
                      ...timeValue,
                      event_end_time: value,
                    })
                  }
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider> */}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                margin='normal'
                fullWidth
                id='file'
                label='Choose event Photo'
                InputLabelProps={{ shrink: true }}
                {...register('event_photo')}
                autoComplete='file'
                type='file'
                autoFocus
              />
            </Grid>
            {selectedEventType === 1 && (
              <Grid style={{ marginTop: '1rem' }} item xs={12} md={6} lg={4}>
                <Autocomplete
                  multiple
                  id='tags-filled'
                  options={allSpeakers.map(
                    (option: ISpeaker) =>
                      option?.guest_speaker_id +
                      ' ' +
                      option?.guest_speaker_name
                  )}
                  freeSolo
                  onChange={(event: any, newValue: any) =>
                    setSpeakerId(newValue)
                  }
                  renderTags={(value: readonly any[], getTagProps) =>
                    value.map((option: any, index: number) => (
                      <Chip
                        variant='filled'
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Select speaker'
                      placeholder='More..'
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12} md={6} lg={12}>
              <TextField
                margin='normal'
                fullWidth
                multiline
                className={classes.inputText}
                InputProps={{ classes }}
                rows={4}
                id='event description'
                label='Enter event description'
                {...register('event_description')}
                type='text'
                autoFocus
              />
            </Grid>
          </Grid>
          {loading ? (
            <Button
              disabled
              fullWidth
              variant='contained'
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Loading...
            </Button>
          ) : (
            <Button
              type='submit'
              fullWidth
              variant='contained'
              disableElevation
              sx={{
                mt: 3,
                mb: 2,
                py: 1,
              }}
            >
              Create Event
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CreateEvent;
