// server of jobayer
// export const url = 'http://192.168.0.236:5000';
// export const url = 'https://atf-server.queenconnect.world';
export const url = 'https://server.dhakatravelmart.info';

export const imgUrl: string =
  'https://m360ict.s3.ap-south-1.amazonaws.com/asian_tourism_fair';

//======== Auth context helper ========//
export const SET_SIGNUP_DATA = 'SET_SIGNUP_DATA';
export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const IMAGE_CHANGE_SUCCESS = 'IMAGE_CHANGE_SUCCESS';
export const AUTH_USER_FAILED = 'AUTH_USER_FAILED';
export const SUPPORT_CONVERSATION_MGS_ID = 'SUPPORT_CONVERSATION_MGS_ID';
export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const FETCH_NOTIFICATION = 'FETCH_NOTIFICATION';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';

// Auth loading state
export const CHANGE_LOADING_STATE = 'CHANGE_LOADING_STATE';

// export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
// export const AUTH_USER_SUCCESS = 'AUTH_USER_SUCCESS';
export const MODAL_SHOW = 'MODAL_SHOW';
export const MODAL_HIDE = 'MODAL_HIDE';
