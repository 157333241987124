import { ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  CardHeader,
  Container,
  Grid,
  Button,
  SelectChangeEvent,
} from '@mui/material';
import { Iadmin } from 'src/Types/module/admin/adminTypes';
import AdminTableRow from './AdminTableRow';
import { TableLoader, TableNotFound } from 'src/Utils/TableLoader';
import { applyPagination } from 'src/Utils/CommonAllShowUtils';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import CreateAdmin from './CreateAdmin';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';

const AdminTable = () => {
  const [limit, setLimit] = useState<number>(25);
  const [allAdmins, setAllAdmins] = useState<Iadmin[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams({
    status: 'pending',
    page: '0',
  });
  const status = searchParams.get('status');
  const page = parseInt(searchParams.get('page'));

  // Modal
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        axios
          .get(`${url}/api/atab/admin/get/all/status/${status}`)
          .then(function (res) {
            console.log(res.data.data);
            if (res.data.success) {
              setAllAdmins(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setAllAdmins([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [status]);

  const statusOptions = [
    {
      id: 'active',
      name: 'Active',
    },
    {
      id: 'pending',
      name: 'Pending',
    },
    {
      id: 'rejected',
      name: 'Rejected',
    },
  ];
  const handlePageChange = (event: any, newPage: number): void => {
    searchParams.set('page', newPage.toString());
    setSearchParams(searchParams);
  };
  const handleStatus = (e: SelectChangeEvent<string>) => {
    searchParams.set('status', e.target.value);
    setSearchParams(searchParams);
  };
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };
  const paginatedInvoice = applyPagination(allAdmins, page, limit);

  return (
    <Container sx={{ mt: 4 }} maxWidth='xl'>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='stretch'
        spacing={3}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 2,
                  }}
                >
                  <FormControl variant='outlined' sx={{ width: '150px' }}>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={status}
                      onChange={(e) => handleStatus(e)}
                      label='Status'
                      autoWidth
                    >
                      {statusOptions.map((statusOption) => (
                        <MenuItem key={statusOption.id} value={statusOption.id}>
                          {statusOption.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button onClick={() => setOpen(true)} variant='contained'>
                    Create Admin
                  </Button>
                </Box>
              }
              title='All Admin'
            />
            <Divider />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Role</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableLoader />
                  ) : allAdmins.length ? (
                    <>
                      {paginatedInvoice.map((allAdmin: Iadmin) => {
                        return (
                          <AdminTableRow
                            key={allAdmin.user_admin_id}
                            allAdmin={allAdmin}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <TableNotFound />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box p={2}>
              <TablePagination
                component='div'
                count={allAdmins.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[25, 50, 75, 100]}
              />
            </Box>
            {/* Modal */}
            <ModalsWrapper
              setShowModal={setOpen}
              showModal={open}
              modalData={{ title: 'Create Admin' }}
            >
              <CreateAdmin handleClose={setOpen} setAllAdmins={setAllAdmins} />
            </ModalsWrapper>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminTable;
