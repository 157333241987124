import { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import { IAdminFair } from 'src/Types/module/fairInvoice/fairInvoiceType';
import FairTable from './FairTable';
import { Helmet } from 'react-helmet-async';

const FairShow = () => {
  const [allAdmins, setAllAdmins] = useState<IAdminFair[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [status, setStatus] = useState<string>('upcoming');

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        axios
          .get(`${url}/api/fair/get/all/status/or/all/${status}`)
          .then(function (res) {
            if (res.data.success) {
              setAllAdmins(res.data.data);
              setLoading(false);
            } else {
              setLoading(false);
            }
          })
          .catch(function (err) {
            setAllAdmins([]);
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [status]);

  return (
    <>
      <Helmet>
        <title>Dhaka Travel Mart - Fair list</title>
      </Helmet>
      <Card>
        <FairTable
          loading={loading}
          allAdmin={allAdmins}
          setStatus={setStatus}
          status={status}
        />
      </Card>
    </>
  );
};

export default FairShow;
