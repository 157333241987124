import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';

import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { url } from 'src/Helpers/utils/Constant';
import { useParams } from 'react-router';
import SuspenseLoader from 'src/components/SuspenseLoader';
import EditIcon from '@mui/icons-material/Edit';
import ModalsWrapper from 'src/components/Modals/ModalsWrapper';
import SingleLeadDetails from './SingleLeadDetails';

const SinglLead = () => {
  const [lead, setLead] = useState();
  const [loading, setLoading] = useState<boolean>(false);

  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    (async function () {
      try {
        const uri = `${url}/api/fair/lead/${id}`;
        const { data } = await axios.get(uri);
        if (data.success) {
          setLead(data.data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [id]);

  return (
    <div>
      <Helmet>
        <title>Dhaka Travel Mart - Lead</title>
      </Helmet>
      <div>
        <Container sx={{ mt: 2, mb: 15 }} maxWidth='xl'>
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '5px',
                }}
              >
                <Typography variant='h4' color='text.primary'>
                  Single Lead Information
                </Typography>
              </Box>
              <Divider />
              {loading ? <SuspenseLoader /> : <SingleLeadDetails lead={lead} />}
            </CardContent>
          </Card>
        </Container>
      </div>
    </div>
  );
};

export default SinglLead;
