import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import { url } from 'src/Helpers/utils/Constant';
import { SingleExhibitorInvoiceInfo } from 'src/Utils/type';
import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import { getStatusLabel } from 'src/Utils/Combined';
import EditIcon from '@mui/icons-material/Edit';
import ExhibitorInvoiceUpdateForm from './ExhibitorInvoiceUpdateForm';
import { ISingleExhibitorInvoice } from 'src/Types/module/invoice/singleExhibitorInvoice';
import { SimCardDownload } from '@mui/icons-material';
import { useReactToPrint } from 'react-to-print';
import DHK from '../../../../../assets/image/Dhaka Travel Mart.jpg';

const ExhibitorSingleInvoice = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [singleExhibitorInvoiceInfo, setSingleExhibitorInvoiceInfo] =
    useState<ISingleExhibitorInvoice>({} as ISingleExhibitorInvoice);
  const [loading, setLoading] = useState<Boolean>(false);
  const [reload, setReload] = useState<Boolean>(false);
  const { singleExhibitorInvoiceId } = useParams();

  useEffect(() => {
    setLoading(true);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    axios
      .get(
        `${url}/api/atab/fair-invoice/get/single/fair-invoice/${singleExhibitorInvoiceId}`,
        {
          cancelToken: source.token,
        }
      )
      .then((data) => {
        setSingleExhibitorInvoiceInfo(data.data.data[0]);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          setLoading(false);
        } else {
          // handle error
          setLoading(false);
        }
      });
    return () => {
      // cancel the request before component unmounts
      source.cancel();
    };
  }, [reload, singleExhibitorInvoiceId]);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const componentRef = useRef(null);

  return (
    <div>
      <Helmet>
        <title>Dhaka Travel Mart - Single exhibitor Invoice information</title>
      </Helmet>

      <Container sx={{ mt: 4 }} maxWidth='xl'>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          {!loading ? (
            <Container>
              <Card>
                <Box textAlign='end' sx={{ p: 2 }}>
                  <Tooltip title='Edit'>
                    <IconButton onClick={handleOpen} sx={{ cursor: 'pointer' }}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Print Invoice'>
                    <IconButton
                      onClick={handlePrint}
                      sx={{
                        cursor: 'pointer',
                        color: theme.colors.primary.main,
                      }}
                    >
                      <SimCardDownload />
                    </IconButton>
                  </Tooltip>
                </Box>
                <CardContent ref={componentRef}>
                  <Box>
                    <img
                      style={{ width: '100px' }}
                      src={DHK}
                      alt='Dhaka Travel Mart'
                      className='invoice_image'
                    />
                    <Typography
                      textAlign='center'
                      gutterBottom
                      variant='h2'
                      component='div'
                    >
                      Single Exhibitor Invoice Information
                    </Typography>
                    <Divider />
                    <Box sx={{ m: 3 }}>
                      <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                      >
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Fair member ID:
                          </Typography>
                          {singleExhibitorInvoiceInfo?.user_fair_member_id}
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Contact name:
                          </Typography>
                          {
                            singleExhibitorInvoiceInfo?.user_fair_member_contact_name
                          }
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Invoice payment method:
                          </Typography>
                          {
                            singleExhibitorInvoiceInfo?.fair_invoice_payment_method
                          }
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Fair invoice ID:
                          </Typography>
                          {singleExhibitorInvoiceInfo?.fair_invoice_id}
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Invoice transaction ID:
                          </Typography>
                          {
                            singleExhibitorInvoiceInfo?.fair_invoice_transaction_id
                          }
                        </Grid>
                        {singleExhibitorInvoiceInfo?.fair_invoice_remark && (
                          <Grid item xs={2} sm={4} md={4}>
                            <Typography
                              variant='body1'
                              fontWeight='bold'
                              color='text.primary'
                              display='inline'
                              sx={{ pr: 1 }}
                              gutterBottom
                            >
                              Invoice remark:
                            </Typography>
                            {singleExhibitorInvoiceInfo?.fair_invoice_remark}
                          </Grid>
                        )}
                        {singleExhibitorInvoiceInfo?.fair_invoice_transaction_date && (
                          <Grid item xs={2} sm={4} md={4}>
                            <Typography
                              variant='body1'
                              fontWeight='bold'
                              color='text.primary'
                              display='inline'
                              sx={{ pr: 1 }}
                              gutterBottom
                            >
                              Transaction date:
                            </Typography>
                            {moment(
                              singleExhibitorInvoiceInfo?.fair_invoice_transaction_date
                            ).format('LL')}
                          </Grid>
                        )}
                        {singleExhibitorInvoiceInfo?.invoice_issue_date && (
                          <Grid item xs={2} sm={4} md={4}>
                            <Typography
                              variant='body1'
                              fontWeight='bold'
                              color='text.primary'
                              display='inline'
                              sx={{ pr: 1 }}
                              gutterBottom
                            >
                              Issue date:
                            </Typography>
                            {moment(
                              singleExhibitorInvoiceInfo?.invoice_issue_date
                            ).format('LL')}
                          </Grid>
                        )}
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Invoice status:
                          </Typography>
                          {getStatusLabel(
                            singleExhibitorInvoiceInfo?.fair_invoice_status
                          )}
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                          <Typography
                            variant='body1'
                            fontWeight='bold'
                            color='text.primary'
                            display='inline'
                            sx={{ pr: 1 }}
                            gutterBottom
                          >
                            Total amount:
                          </Typography>
                          {singleExhibitorInvoiceInfo?.total_amount}
                        </Grid>
                      </Grid>
                    </Box>
                    <Dialog
                      fullScreen={fullScreen}
                      open={open}
                      onClose={handleClose}
                      aria-labelledby='responsive-dialog-title'
                    >
                      <DialogTitle sx={{ p: 4 }} id='responsive-dialog-title'>
                        <Typography variant='h4'>
                          Update exhibitor invoice
                        </Typography>
                      </DialogTitle>
                      <DialogContent>
                        <ExhibitorInvoiceUpdateForm
                          details={singleExhibitorInvoiceInfo}
                          setReload={setReload}
                          handleClose={handleClose}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button autoFocus onClick={handleClose}>
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>

                    <div className='invoice_footer_form'>
                      <h2>Received with terms</h2>
                      <h2 style={{ marginRight: '100px' }}>Authority</h2>
                    </div>
                  </Box>
                </CardContent>
              </Card>
            </Container>
          ) : (
            <GlobalLoader />
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default ExhibitorSingleInvoice;
