import { Grid } from '@mui/material';
import { Container } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import GlobalLoader from 'src/components/SuspenseLoader/GlobalLoader';
import { url } from 'src/Helpers/utils/Constant';
import { singleVisitorInfo } from 'src/Utils/type';
import SingleVisitorInfo from './SingleVisitorInfo';
import ProductByVisitor from './ProductByVsitor';

const SingleVisitor = () => {
  const [singleVisitorInfo, setSingleVisitorInfo] =
    useState<singleVisitorInfo>(null);
  const [singleVisitorValue, setSingleVisitorValue] = useState<any[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const { singleVisitorId } = useParams();
  const [value, setValue] = useState<string>('product');

  useEffect(() => {
    const controller = new AbortController();
    (async function () {
      try {
        setLoading(true);
        const { data } = await axios.get(
          `${url}/api/fair/visitor/get/single/visitor/${singleVisitorId}`
        );
        if (data.success) {
          setSingleVisitorInfo(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setSingleVisitorInfo(null);
      }
    })();
    return () => {
      // cancel the request before component unmounts
      controller.abort();
    };
  }, [singleVisitorId]);

  useEffect(() => {
    const controller = new AbortController();
    (async function () {
      setLoading(true);
      try {
        if (value === 'product') {
          setSingleVisitorValue([]);
          const { data } = await axios.get(
            `${url}/api/fair/visitor/wish-list/${singleVisitorId}`
          );
          if (data.success) {
            setSingleVisitorValue(data.data);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      } catch (error) {
        setLoading(false);
      }
    })();
    return () => {
      controller.abort();
    };
  }, [singleVisitorId, value]);

  return (
    <div>
      <Helmet>
        <title>Dhaka Travel Mart - Single visitor information</title>
      </Helmet>

      <Container sx={{ mt: 4 }} maxWidth='xl'>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
        >
          {!loading ? (
            <>
              {singleVisitorInfo && (
                <SingleVisitorInfo visitorInfo={singleVisitorInfo} />
              )}
              <ProductByVisitor
                setValue={setValue}
                value={value}
                loading={loading}
                visitorProduct={singleVisitorValue}
              />
            </>
          ) : (
            <GlobalLoader />
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default SingleVisitor;
